﻿@import './variables';

::-webkit-scrollbar {
    width: 0.3rem;
    height: 0;
    pointer-events: none;
}

::-webkit-scrollbar-thumb {
    background: $gray-400;
    border-radius: 0.3rem;

}

::-webkit-scrollbar-track {
    background: $gray-100;

}
@mixin sectionColors($sectionColor, $sectionFilterColor: "") {
    .section-color {
        color: $sectionColor !important;

      
    }

    .section-switch-color {
        & .Mui-checked {
            color: $sectionColor;

            &.Mui-disabled {
                opacity: 0.5;

                &+.MuiSwitch-track {
                    opacity: 0.5;
                }
            }

            &+.MuiSwitch-track {
                background-color: $sectionColor !important;
            }
        }
    }

    .section-selected-side-border {
        border-left: 0.3rem solid $sectionColor !important;
    }

    .section-fill {
        fill: $sectionColor;
    }

    .section-bg-color {
        background-color: $sectionColor;

        &:hover {
            background-color: $sectionColor;
        }
    }

    .section-hover-outline {
        color: $sectionColor !important;

        &:hover {
            background-color: white;
            border: 0.1rem solid $sectionColor;
        }
    }

    .disabled-section-button {
        opacity: 0.5
    }

    .section-outline-color {
        border: 0.1rem solid $sectionColor;
        color: $sectionColor;
    }

    .section-bg-filter {
        background-color: $sectionFilterColor;
    }

    .section-border {
        border: 1px solid $sectionColor !important;
    }

    button.section-bg-filter:hover {
        background-color: $sectionFilterColor;
    }

    button.section-bg-color:hover,
    label[role="button"].section-bg-color:hover {
        background-color: $sectionColor;
    }

    .MuiPaginationItem-page.Mui-selected {
        color: $sectionColor;
        background-color: rgba(red($sectionColor), green($sectionColor), blue($sectionColor), 0.1);
        pointer-events: none;
    }

    .section-input:focus-within {
        border: solid .1rem $sectionColor !important;
    }

    .section-product-name {
        border-bottom: solid .1rem $gray-400 !important;
        border-radius: 0;
        // &::after, &::before, &:hover{ 
        //     border-bottom: solid .1rem $sectionColor !important;
        // }
    }

    .section-select.Mui-focused {
        border: solid .1rem $sectionColor !important;
        border-radius: 0.3rem;
    }

    .section-top-border {
        border-top: 0.125rem solid $sectionColor !important;
    }

    .checkbox-section-color {
        &.Mui-checked {
            color: $sectionColor;
            opacity: 1;
        }

        opacity: 0.5;
    }

    .section-1px-border {
        border: 1px solid $sectionColor !important;
    }

    .document-card:hover {
        border: 1px solid $sectionColor !important;

        .btn-direct-upload {
            fill: $sectionColor;
        }
    }

    //date range picker

    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
        color: $sectionColor !important;
    }

    .rdrDayInPreview,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
        border-bottom: $sectionColor solid 1px !important;
        border-top: $sectionColor solid 1px !important;
    }

    .rdrDayStartPreview,
    .rdrDayStartOfWeek .rdrDayInPreview,
    .rdrDayStartOfWeek .rdrDayEndPreview {
        border-left: $sectionColor solid 1px !important;
    }

    .rdrDayEndPreview,
    .rdrDayEndOfWeek .rdrDayInPreview,
    .rdrDayEndOfWeek .rdrDayStartPreview {
        border-right: $sectionColor solid 1px !important;

    }

    .rdrDayToday .rdrDayNumber span:after {
        background: $sectionColor
    }

    .section-edit-view-top-border{
        border-top:.75rem solid $sectionColor
    }
}


html {
    font-size: 100%;
}

body {
    overflow: hidden;
    height: 100vh;
}

#root {
    height: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}

.Requests {
    @include sectionColors($order-inv-color, $order-inv-25)
}

.Biomarkers {
    @include sectionColors($biomarkers-color, $biomarkers-25)
}

.Protocols {
    @include sectionColors($protocols-color, $protocols-25)
}


.Reports {
    @include sectionColors($reports-color)
}



.Operations {
    @include sectionColors($operations-color, $operations-25)
}

.Accounting {
    @include sectionColors($acc-color, $accounting-25)
}

.LabManagement {
    @include sectionColors($lab-man-color, $lab-man-25)
}

.Users {
    @include sectionColors($user-color, $users-25)
}

.TimeKeeper {
    @include sectionColors($timekeeper-color, $timekeeper-25)
}

//input styles
.section-input,
.section-select {

    //border-bottom: 0.12rem solid $gray-400!important;
    &.placeholder {
        & .MuiSelect-select {
            color: $gray-700;
        }
    }
}

.styled-outline-input {
    padding: 0 1rem;
    height: 2.37rem;
    border: 0.125rem solid $gray-550;
    border-radius: 0.3rem !important;
}

.read-only-input {
    background-color: #F7F8F9;

    &:hover {
        background-color: #F7F8F9 !important
    }
}

.login-styled-outline-input {
    border: 0.031px solid $gray-400;
    border-radius: 0.003rem;
    padding: 0rem !important;
    height: fit-content !important;

    & input {
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
    }
}

.styled-outline-input,
.login-styled-outline-input input,
.read-only-input,
.section-select,
.section-input,
.section-product-name {

    &::before,
    &::after {
        border-bottom: none !important
    }

    ;
}


//icon colors
.icon-green {
    fill: #00CA72;

    & .cls-2 {
        fill: #00CA72;
    }
    color: #00CA72 !important
}

.icon-black {
    fill: $black-87;
    color: $black-87 !important;
}

.icon-gray {
    fill: $gray-400;
    color: $gray-400 !important;
}

.icon-dark-gray {
    fill: $gray-600;
    color: $gray-600 !important;
}
.icon-timekeeper {
    fill: $timekeeper-color;
    color: $timekeeper-color !important;
}
.icon-labManagement {
    fill: $lab-man-color;
    color: $lab-man-color !important;
}
.icon-red {
    fill: $danger-color;
    color: $danger-color !important;
}

.icon-white {
    fill: white;
}
.icon-blue{
    fill: #5F79E2;
    color: #5F79E2 !important;
}


//popover styles
.column-options-popover {
    &.MuiPopover-root .MuiPaper-root {
        border-radius: 0px 0px 4px 4px;
    }

    & .MuiCheckbox-root {
        padding: 0 0.3rem 0 0;

        & .MuiSvgIcon-root {
            font-size: 1.1rem
        }
    }

    & .MuiRadio-root {
        padding: 0 0.4rem 0 0;

        & .MuiSvgIcon-root {
            font-size: 1.1rem
        }
    }

    & [role="radiogroup"] {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        justify-content: space-between;
        align-content: center;
        border-top: 1px solid #ebebeb;
    }
}

//colors
.gray-700 {
    color: $gray-700 !important;
}

.gray-300 {
    color: $gray-300 !important;
}

.gray-700-fill {
    fill: $gray-700;
}

.error {
    color: $danger-color !important;
}

//misc
.react-multi-carousel-list {
    position: unset !important;
}

.placeholderBlack {
    &::placeholder {
        color: $black-87 !important
    }
}


//date range picker
.rdrDefinedRangesWrapper {
    display: none;
}

.rdrCalendarWrapper {
    font-size: 1rem !important;
}

.rdrDateRangePickerWrapper {
    margin: auto
}

.rdrMonthName {
    display: none;
}
