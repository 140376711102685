/*#7D9BAA;*/
::-webkit-scrollbar {
  width: 0.3rem;
  height: 0;
  pointer-events: none;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 0.3rem;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
}

html {
  font-size: 100%;
}

body {
  overflow: hidden;
  height: 100vh;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.Requests .section-color {
  color: #2196F3 !important;
}
.Requests .section-switch-color .Mui-checked {
  color: #2196F3;
}
.Requests .section-switch-color .Mui-checked.Mui-disabled {
  opacity: 0.5;
}
.Requests .section-switch-color .Mui-checked.Mui-disabled + .MuiSwitch-track {
  opacity: 0.5;
}
.Requests .section-switch-color .Mui-checked + .MuiSwitch-track {
  background-color: #2196F3 !important;
}
.Requests .section-selected-side-border {
  border-left: 0.3rem solid #2196F3 !important;
}
.Requests .section-fill {
  fill: #2196F3;
}
.Requests .section-bg-color {
  background-color: #2196F3;
}
.Requests .section-bg-color:hover {
  background-color: #2196F3;
}
.Requests .section-hover-outline {
  color: #2196F3 !important;
}
.Requests .section-hover-outline:hover {
  background-color: white;
  border: 0.1rem solid #2196F3;
}
.Requests .disabled-section-button {
  opacity: 0.5;
}
.Requests .section-outline-color {
  border: 0.1rem solid #2196F3;
  color: #2196F3;
}
.Requests .section-bg-filter {
  background-color: rgba(33, 150, 243, 0.25);
}
.Requests .section-border {
  border: 1px solid #2196F3 !important;
}
.Requests button.section-bg-filter:hover {
  background-color: rgba(33, 150, 243, 0.25);
}
.Requests button.section-bg-color:hover,
.Requests label[role=button].section-bg-color:hover {
  background-color: #2196F3;
}
.Requests .MuiPaginationItem-page.Mui-selected {
  color: #2196F3;
  background-color: rgba(33, 150, 243, 0.1);
  pointer-events: none;
}
.Requests .section-input:focus-within {
  border: solid 0.1rem #2196F3 !important;
}
.Requests .section-product-name {
  border-bottom: solid 0.1rem #bdbdbd !important;
  border-radius: 0;
}
.Requests .section-select.Mui-focused {
  border: solid 0.1rem #2196F3 !important;
  border-radius: 0.3rem;
}
.Requests .section-top-border {
  border-top: 0.125rem solid #2196F3 !important;
}
.Requests .checkbox-section-color {
  opacity: 0.5;
}
.Requests .checkbox-section-color.Mui-checked {
  color: #2196F3;
  opacity: 1;
}
.Requests .section-1px-border {
  border: 1px solid #2196F3 !important;
}
.Requests .document-card:hover {
  border: 1px solid #2196F3 !important;
}
.Requests .document-card:hover .btn-direct-upload {
  fill: #2196F3;
}
.Requests .rdrInRange,
.Requests .rdrStartEdge,
.Requests .rdrEndEdge {
  color: #2196F3 !important;
}
.Requests .rdrDayInPreview,
.Requests .rdrDayStartPreview,
.Requests .rdrDayEndPreview {
  border-bottom: #2196F3 solid 1px !important;
  border-top: #2196F3 solid 1px !important;
}
.Requests .rdrDayStartPreview,
.Requests .rdrDayStartOfWeek .rdrDayInPreview,
.Requests .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left: #2196F3 solid 1px !important;
}
.Requests .rdrDayEndPreview,
.Requests .rdrDayEndOfWeek .rdrDayInPreview,
.Requests .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right: #2196F3 solid 1px !important;
}
.Requests .rdrDayToday .rdrDayNumber span:after {
  background: #2196F3;
}
.Requests .section-edit-view-top-border {
  border-top: 0.75rem solid #2196F3;
}

.Biomarkers .section-color {
  color: #F44336 !important;
}
.Biomarkers .section-switch-color .Mui-checked {
  color: #F44336;
}
.Biomarkers .section-switch-color .Mui-checked.Mui-disabled {
  opacity: 0.5;
}
.Biomarkers .section-switch-color .Mui-checked.Mui-disabled + .MuiSwitch-track {
  opacity: 0.5;
}
.Biomarkers .section-switch-color .Mui-checked + .MuiSwitch-track {
  background-color: #F44336 !important;
}
.Biomarkers .section-selected-side-border {
  border-left: 0.3rem solid #F44336 !important;
}
.Biomarkers .section-fill {
  fill: #F44336;
}
.Biomarkers .section-bg-color {
  background-color: #F44336;
}
.Biomarkers .section-bg-color:hover {
  background-color: #F44336;
}
.Biomarkers .section-hover-outline {
  color: #F44336 !important;
}
.Biomarkers .section-hover-outline:hover {
  background-color: white;
  border: 0.1rem solid #F44336;
}
.Biomarkers .disabled-section-button {
  opacity: 0.5;
}
.Biomarkers .section-outline-color {
  border: 0.1rem solid #F44336;
  color: #F44336;
}
.Biomarkers .section-bg-filter {
  background-color: rgba(244, 67, 54, 0.25);
}
.Biomarkers .section-border {
  border: 1px solid #F44336 !important;
}
.Biomarkers button.section-bg-filter:hover {
  background-color: rgba(244, 67, 54, 0.25);
}
.Biomarkers button.section-bg-color:hover,
.Biomarkers label[role=button].section-bg-color:hover {
  background-color: #F44336;
}
.Biomarkers .MuiPaginationItem-page.Mui-selected {
  color: #F44336;
  background-color: rgba(244, 67, 54, 0.1);
  pointer-events: none;
}
.Biomarkers .section-input:focus-within {
  border: solid 0.1rem #F44336 !important;
}
.Biomarkers .section-product-name {
  border-bottom: solid 0.1rem #bdbdbd !important;
  border-radius: 0;
}
.Biomarkers .section-select.Mui-focused {
  border: solid 0.1rem #F44336 !important;
  border-radius: 0.3rem;
}
.Biomarkers .section-top-border {
  border-top: 0.125rem solid #F44336 !important;
}
.Biomarkers .checkbox-section-color {
  opacity: 0.5;
}
.Biomarkers .checkbox-section-color.Mui-checked {
  color: #F44336;
  opacity: 1;
}
.Biomarkers .section-1px-border {
  border: 1px solid #F44336 !important;
}
.Biomarkers .document-card:hover {
  border: 1px solid #F44336 !important;
}
.Biomarkers .document-card:hover .btn-direct-upload {
  fill: #F44336;
}
.Biomarkers .rdrInRange,
.Biomarkers .rdrStartEdge,
.Biomarkers .rdrEndEdge {
  color: #F44336 !important;
}
.Biomarkers .rdrDayInPreview,
.Biomarkers .rdrDayStartPreview,
.Biomarkers .rdrDayEndPreview {
  border-bottom: #F44336 solid 1px !important;
  border-top: #F44336 solid 1px !important;
}
.Biomarkers .rdrDayStartPreview,
.Biomarkers .rdrDayStartOfWeek .rdrDayInPreview,
.Biomarkers .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left: #F44336 solid 1px !important;
}
.Biomarkers .rdrDayEndPreview,
.Biomarkers .rdrDayEndOfWeek .rdrDayInPreview,
.Biomarkers .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right: #F44336 solid 1px !important;
}
.Biomarkers .rdrDayToday .rdrDayNumber span:after {
  background: #F44336;
}
.Biomarkers .section-edit-view-top-border {
  border-top: 0.75rem solid #F44336;
}

.Protocols .section-color {
  color: #009688 !important;
}
.Protocols .section-switch-color .Mui-checked {
  color: #009688;
}
.Protocols .section-switch-color .Mui-checked.Mui-disabled {
  opacity: 0.5;
}
.Protocols .section-switch-color .Mui-checked.Mui-disabled + .MuiSwitch-track {
  opacity: 0.5;
}
.Protocols .section-switch-color .Mui-checked + .MuiSwitch-track {
  background-color: #009688 !important;
}
.Protocols .section-selected-side-border {
  border-left: 0.3rem solid #009688 !important;
}
.Protocols .section-fill {
  fill: #009688;
}
.Protocols .section-bg-color {
  background-color: #009688;
}
.Protocols .section-bg-color:hover {
  background-color: #009688;
}
.Protocols .section-hover-outline {
  color: #009688 !important;
}
.Protocols .section-hover-outline:hover {
  background-color: white;
  border: 0.1rem solid #009688;
}
.Protocols .disabled-section-button {
  opacity: 0.5;
}
.Protocols .section-outline-color {
  border: 0.1rem solid #009688;
  color: #009688;
}
.Protocols .section-bg-filter {
  background-color: rgba(0, 150, 136, 0.25);
}
.Protocols .section-border {
  border: 1px solid #009688 !important;
}
.Protocols button.section-bg-filter:hover {
  background-color: rgba(0, 150, 136, 0.25);
}
.Protocols button.section-bg-color:hover,
.Protocols label[role=button].section-bg-color:hover {
  background-color: #009688;
}
.Protocols .MuiPaginationItem-page.Mui-selected {
  color: #009688;
  background-color: rgba(0, 150, 136, 0.1);
  pointer-events: none;
}
.Protocols .section-input:focus-within {
  border: solid 0.1rem #009688 !important;
}
.Protocols .section-product-name {
  border-bottom: solid 0.1rem #bdbdbd !important;
  border-radius: 0;
}
.Protocols .section-select.Mui-focused {
  border: solid 0.1rem #009688 !important;
  border-radius: 0.3rem;
}
.Protocols .section-top-border {
  border-top: 0.125rem solid #009688 !important;
}
.Protocols .checkbox-section-color {
  opacity: 0.5;
}
.Protocols .checkbox-section-color.Mui-checked {
  color: #009688;
  opacity: 1;
}
.Protocols .section-1px-border {
  border: 1px solid #009688 !important;
}
.Protocols .document-card:hover {
  border: 1px solid #009688 !important;
}
.Protocols .document-card:hover .btn-direct-upload {
  fill: #009688;
}
.Protocols .rdrInRange,
.Protocols .rdrStartEdge,
.Protocols .rdrEndEdge {
  color: #009688 !important;
}
.Protocols .rdrDayInPreview,
.Protocols .rdrDayStartPreview,
.Protocols .rdrDayEndPreview {
  border-bottom: #009688 solid 1px !important;
  border-top: #009688 solid 1px !important;
}
.Protocols .rdrDayStartPreview,
.Protocols .rdrDayStartOfWeek .rdrDayInPreview,
.Protocols .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left: #009688 solid 1px !important;
}
.Protocols .rdrDayEndPreview,
.Protocols .rdrDayEndOfWeek .rdrDayInPreview,
.Protocols .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right: #009688 solid 1px !important;
}
.Protocols .rdrDayToday .rdrDayNumber span:after {
  background: #009688;
}
.Protocols .section-edit-view-top-border {
  border-top: 0.75rem solid #009688;
}

.Reports .section-color {
  color: #00BCD4 !important;
}
.Reports .section-switch-color .Mui-checked {
  color: #00BCD4;
}
.Reports .section-switch-color .Mui-checked.Mui-disabled {
  opacity: 0.5;
}
.Reports .section-switch-color .Mui-checked.Mui-disabled + .MuiSwitch-track {
  opacity: 0.5;
}
.Reports .section-switch-color .Mui-checked + .MuiSwitch-track {
  background-color: #00BCD4 !important;
}
.Reports .section-selected-side-border {
  border-left: 0.3rem solid #00BCD4 !important;
}
.Reports .section-fill {
  fill: #00BCD4;
}
.Reports .section-bg-color {
  background-color: #00BCD4;
}
.Reports .section-bg-color:hover {
  background-color: #00BCD4;
}
.Reports .section-hover-outline {
  color: #00BCD4 !important;
}
.Reports .section-hover-outline:hover {
  background-color: white;
  border: 0.1rem solid #00BCD4;
}
.Reports .disabled-section-button {
  opacity: 0.5;
}
.Reports .section-outline-color {
  border: 0.1rem solid #00BCD4;
  color: #00BCD4;
}
.Reports .section-bg-filter {
  background-color: "";
}
.Reports .section-border {
  border: 1px solid #00BCD4 !important;
}
.Reports button.section-bg-filter:hover {
  background-color: "";
}
.Reports button.section-bg-color:hover,
.Reports label[role=button].section-bg-color:hover {
  background-color: #00BCD4;
}
.Reports .MuiPaginationItem-page.Mui-selected {
  color: #00BCD4;
  background-color: rgba(0, 188, 212, 0.1);
  pointer-events: none;
}
.Reports .section-input:focus-within {
  border: solid 0.1rem #00BCD4 !important;
}
.Reports .section-product-name {
  border-bottom: solid 0.1rem #bdbdbd !important;
  border-radius: 0;
}
.Reports .section-select.Mui-focused {
  border: solid 0.1rem #00BCD4 !important;
  border-radius: 0.3rem;
}
.Reports .section-top-border {
  border-top: 0.125rem solid #00BCD4 !important;
}
.Reports .checkbox-section-color {
  opacity: 0.5;
}
.Reports .checkbox-section-color.Mui-checked {
  color: #00BCD4;
  opacity: 1;
}
.Reports .section-1px-border {
  border: 1px solid #00BCD4 !important;
}
.Reports .document-card:hover {
  border: 1px solid #00BCD4 !important;
}
.Reports .document-card:hover .btn-direct-upload {
  fill: #00BCD4;
}
.Reports .rdrInRange,
.Reports .rdrStartEdge,
.Reports .rdrEndEdge {
  color: #00BCD4 !important;
}
.Reports .rdrDayInPreview,
.Reports .rdrDayStartPreview,
.Reports .rdrDayEndPreview {
  border-bottom: #00BCD4 solid 1px !important;
  border-top: #00BCD4 solid 1px !important;
}
.Reports .rdrDayStartPreview,
.Reports .rdrDayStartOfWeek .rdrDayInPreview,
.Reports .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left: #00BCD4 solid 1px !important;
}
.Reports .rdrDayEndPreview,
.Reports .rdrDayEndOfWeek .rdrDayInPreview,
.Reports .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right: #00BCD4 solid 1px !important;
}
.Reports .rdrDayToday .rdrDayNumber span:after {
  background: #00BCD4;
}
.Reports .section-edit-view-top-border {
  border-top: 0.75rem solid #00BCD4;
}

.Operations .section-color {
  color: #3F51B5 !important;
}
.Operations .section-switch-color .Mui-checked {
  color: #3F51B5;
}
.Operations .section-switch-color .Mui-checked.Mui-disabled {
  opacity: 0.5;
}
.Operations .section-switch-color .Mui-checked.Mui-disabled + .MuiSwitch-track {
  opacity: 0.5;
}
.Operations .section-switch-color .Mui-checked + .MuiSwitch-track {
  background-color: #3F51B5 !important;
}
.Operations .section-selected-side-border {
  border-left: 0.3rem solid #3F51B5 !important;
}
.Operations .section-fill {
  fill: #3F51B5;
}
.Operations .section-bg-color {
  background-color: #3F51B5;
}
.Operations .section-bg-color:hover {
  background-color: #3F51B5;
}
.Operations .section-hover-outline {
  color: #3F51B5 !important;
}
.Operations .section-hover-outline:hover {
  background-color: white;
  border: 0.1rem solid #3F51B5;
}
.Operations .disabled-section-button {
  opacity: 0.5;
}
.Operations .section-outline-color {
  border: 0.1rem solid #3F51B5;
  color: #3F51B5;
}
.Operations .section-bg-filter {
  background-color: rgba(63, 81, 181, 0.25);
}
.Operations .section-border {
  border: 1px solid #3F51B5 !important;
}
.Operations button.section-bg-filter:hover {
  background-color: rgba(63, 81, 181, 0.25);
}
.Operations button.section-bg-color:hover,
.Operations label[role=button].section-bg-color:hover {
  background-color: #3F51B5;
}
.Operations .MuiPaginationItem-page.Mui-selected {
  color: #3F51B5;
  background-color: rgba(63, 81, 181, 0.1);
  pointer-events: none;
}
.Operations .section-input:focus-within {
  border: solid 0.1rem #3F51B5 !important;
}
.Operations .section-product-name {
  border-bottom: solid 0.1rem #bdbdbd !important;
  border-radius: 0;
}
.Operations .section-select.Mui-focused {
  border: solid 0.1rem #3F51B5 !important;
  border-radius: 0.3rem;
}
.Operations .section-top-border {
  border-top: 0.125rem solid #3F51B5 !important;
}
.Operations .checkbox-section-color {
  opacity: 0.5;
}
.Operations .checkbox-section-color.Mui-checked {
  color: #3F51B5;
  opacity: 1;
}
.Operations .section-1px-border {
  border: 1px solid #3F51B5 !important;
}
.Operations .document-card:hover {
  border: 1px solid #3F51B5 !important;
}
.Operations .document-card:hover .btn-direct-upload {
  fill: #3F51B5;
}
.Operations .rdrInRange,
.Operations .rdrStartEdge,
.Operations .rdrEndEdge {
  color: #3F51B5 !important;
}
.Operations .rdrDayInPreview,
.Operations .rdrDayStartPreview,
.Operations .rdrDayEndPreview {
  border-bottom: #3F51B5 solid 1px !important;
  border-top: #3F51B5 solid 1px !important;
}
.Operations .rdrDayStartPreview,
.Operations .rdrDayStartOfWeek .rdrDayInPreview,
.Operations .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left: #3F51B5 solid 1px !important;
}
.Operations .rdrDayEndPreview,
.Operations .rdrDayEndOfWeek .rdrDayInPreview,
.Operations .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right: #3F51B5 solid 1px !important;
}
.Operations .rdrDayToday .rdrDayNumber span:after {
  background: #3F51B5;
}
.Operations .section-edit-view-top-border {
  border-top: 0.75rem solid #3F51B5;
}

.Accounting .section-color {
  color: #A26FF0 !important;
}
.Accounting .section-switch-color .Mui-checked {
  color: #A26FF0;
}
.Accounting .section-switch-color .Mui-checked.Mui-disabled {
  opacity: 0.5;
}
.Accounting .section-switch-color .Mui-checked.Mui-disabled + .MuiSwitch-track {
  opacity: 0.5;
}
.Accounting .section-switch-color .Mui-checked + .MuiSwitch-track {
  background-color: #A26FF0 !important;
}
.Accounting .section-selected-side-border {
  border-left: 0.3rem solid #A26FF0 !important;
}
.Accounting .section-fill {
  fill: #A26FF0;
}
.Accounting .section-bg-color {
  background-color: #A26FF0;
}
.Accounting .section-bg-color:hover {
  background-color: #A26FF0;
}
.Accounting .section-hover-outline {
  color: #A26FF0 !important;
}
.Accounting .section-hover-outline:hover {
  background-color: white;
  border: 0.1rem solid #A26FF0;
}
.Accounting .disabled-section-button {
  opacity: 0.5;
}
.Accounting .section-outline-color {
  border: 0.1rem solid #A26FF0;
  color: #A26FF0;
}
.Accounting .section-bg-filter {
  background-color: rgba(162, 111, 240, 0.25);
}
.Accounting .section-border {
  border: 1px solid #A26FF0 !important;
}
.Accounting button.section-bg-filter:hover {
  background-color: rgba(162, 111, 240, 0.25);
}
.Accounting button.section-bg-color:hover,
.Accounting label[role=button].section-bg-color:hover {
  background-color: #A26FF0;
}
.Accounting .MuiPaginationItem-page.Mui-selected {
  color: #A26FF0;
  background-color: rgba(162, 111, 240, 0.1);
  pointer-events: none;
}
.Accounting .section-input:focus-within {
  border: solid 0.1rem #A26FF0 !important;
}
.Accounting .section-product-name {
  border-bottom: solid 0.1rem #bdbdbd !important;
  border-radius: 0;
}
.Accounting .section-select.Mui-focused {
  border: solid 0.1rem #A26FF0 !important;
  border-radius: 0.3rem;
}
.Accounting .section-top-border {
  border-top: 0.125rem solid #A26FF0 !important;
}
.Accounting .checkbox-section-color {
  opacity: 0.5;
}
.Accounting .checkbox-section-color.Mui-checked {
  color: #A26FF0;
  opacity: 1;
}
.Accounting .section-1px-border {
  border: 1px solid #A26FF0 !important;
}
.Accounting .document-card:hover {
  border: 1px solid #A26FF0 !important;
}
.Accounting .document-card:hover .btn-direct-upload {
  fill: #A26FF0;
}
.Accounting .rdrInRange,
.Accounting .rdrStartEdge,
.Accounting .rdrEndEdge {
  color: #A26FF0 !important;
}
.Accounting .rdrDayInPreview,
.Accounting .rdrDayStartPreview,
.Accounting .rdrDayEndPreview {
  border-bottom: #A26FF0 solid 1px !important;
  border-top: #A26FF0 solid 1px !important;
}
.Accounting .rdrDayStartPreview,
.Accounting .rdrDayStartOfWeek .rdrDayInPreview,
.Accounting .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left: #A26FF0 solid 1px !important;
}
.Accounting .rdrDayEndPreview,
.Accounting .rdrDayEndOfWeek .rdrDayInPreview,
.Accounting .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right: #A26FF0 solid 1px !important;
}
.Accounting .rdrDayToday .rdrDayNumber span:after {
  background: #A26FF0;
}
.Accounting .section-edit-view-top-border {
  border-top: 0.75rem solid #A26FF0;
}

.LabManagement .section-color {
  color: rgb(125, 155, 170) !important;
}
.LabManagement .section-switch-color .Mui-checked {
  color: rgb(125, 155, 170);
}
.LabManagement .section-switch-color .Mui-checked.Mui-disabled {
  opacity: 0.5;
}
.LabManagement .section-switch-color .Mui-checked.Mui-disabled + .MuiSwitch-track {
  opacity: 0.5;
}
.LabManagement .section-switch-color .Mui-checked + .MuiSwitch-track {
  background-color: rgb(125, 155, 170) !important;
}
.LabManagement .section-selected-side-border {
  border-left: 0.3rem solid rgb(125, 155, 170) !important;
}
.LabManagement .section-fill {
  fill: rgb(125, 155, 170);
}
.LabManagement .section-bg-color {
  background-color: rgb(125, 155, 170);
}
.LabManagement .section-bg-color:hover {
  background-color: rgb(125, 155, 170);
}
.LabManagement .section-hover-outline {
  color: rgb(125, 155, 170) !important;
}
.LabManagement .section-hover-outline:hover {
  background-color: white;
  border: 0.1rem solid rgb(125, 155, 170);
}
.LabManagement .disabled-section-button {
  opacity: 0.5;
}
.LabManagement .section-outline-color {
  border: 0.1rem solid rgb(125, 155, 170);
  color: rgb(125, 155, 170);
}
.LabManagement .section-bg-filter {
  background-color: rgba(125, 155, 170, 0.25);
}
.LabManagement .section-border {
  border: 1px solid rgb(125, 155, 170) !important;
}
.LabManagement button.section-bg-filter:hover {
  background-color: rgba(125, 155, 170, 0.25);
}
.LabManagement button.section-bg-color:hover,
.LabManagement label[role=button].section-bg-color:hover {
  background-color: rgb(125, 155, 170);
}
.LabManagement .MuiPaginationItem-page.Mui-selected {
  color: rgb(125, 155, 170);
  background-color: rgba(125, 155, 170, 0.1);
  pointer-events: none;
}
.LabManagement .section-input:focus-within {
  border: solid 0.1rem rgb(125, 155, 170) !important;
}
.LabManagement .section-product-name {
  border-bottom: solid 0.1rem #bdbdbd !important;
  border-radius: 0;
}
.LabManagement .section-select.Mui-focused {
  border: solid 0.1rem rgb(125, 155, 170) !important;
  border-radius: 0.3rem;
}
.LabManagement .section-top-border {
  border-top: 0.125rem solid rgb(125, 155, 170) !important;
}
.LabManagement .checkbox-section-color {
  opacity: 0.5;
}
.LabManagement .checkbox-section-color.Mui-checked {
  color: rgb(125, 155, 170);
  opacity: 1;
}
.LabManagement .section-1px-border {
  border: 1px solid rgb(125, 155, 170) !important;
}
.LabManagement .document-card:hover {
  border: 1px solid rgb(125, 155, 170) !important;
}
.LabManagement .document-card:hover .btn-direct-upload {
  fill: rgb(125, 155, 170);
}
.LabManagement .rdrInRange,
.LabManagement .rdrStartEdge,
.LabManagement .rdrEndEdge {
  color: rgb(125, 155, 170) !important;
}
.LabManagement .rdrDayInPreview,
.LabManagement .rdrDayStartPreview,
.LabManagement .rdrDayEndPreview {
  border-bottom: rgb(125, 155, 170) solid 1px !important;
  border-top: rgb(125, 155, 170) solid 1px !important;
}
.LabManagement .rdrDayStartPreview,
.LabManagement .rdrDayStartOfWeek .rdrDayInPreview,
.LabManagement .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left: rgb(125, 155, 170) solid 1px !important;
}
.LabManagement .rdrDayEndPreview,
.LabManagement .rdrDayEndOfWeek .rdrDayInPreview,
.LabManagement .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right: rgb(125, 155, 170) solid 1px !important;
}
.LabManagement .rdrDayToday .rdrDayNumber span:after {
  background: rgb(125, 155, 170);
}
.LabManagement .section-edit-view-top-border {
  border-top: 0.75rem solid rgb(125, 155, 170);
}

.Users .section-color {
  color: #795548 !important;
}
.Users .section-switch-color .Mui-checked {
  color: #795548;
}
.Users .section-switch-color .Mui-checked.Mui-disabled {
  opacity: 0.5;
}
.Users .section-switch-color .Mui-checked.Mui-disabled + .MuiSwitch-track {
  opacity: 0.5;
}
.Users .section-switch-color .Mui-checked + .MuiSwitch-track {
  background-color: #795548 !important;
}
.Users .section-selected-side-border {
  border-left: 0.3rem solid #795548 !important;
}
.Users .section-fill {
  fill: #795548;
}
.Users .section-bg-color {
  background-color: #795548;
}
.Users .section-bg-color:hover {
  background-color: #795548;
}
.Users .section-hover-outline {
  color: #795548 !important;
}
.Users .section-hover-outline:hover {
  background-color: white;
  border: 0.1rem solid #795548;
}
.Users .disabled-section-button {
  opacity: 0.5;
}
.Users .section-outline-color {
  border: 0.1rem solid #795548;
  color: #795548;
}
.Users .section-bg-filter {
  background-color: rgba(121, 85, 72, 0.25);
}
.Users .section-border {
  border: 1px solid #795548 !important;
}
.Users button.section-bg-filter:hover {
  background-color: rgba(121, 85, 72, 0.25);
}
.Users button.section-bg-color:hover,
.Users label[role=button].section-bg-color:hover {
  background-color: #795548;
}
.Users .MuiPaginationItem-page.Mui-selected {
  color: #795548;
  background-color: rgba(121, 85, 72, 0.1);
  pointer-events: none;
}
.Users .section-input:focus-within {
  border: solid 0.1rem #795548 !important;
}
.Users .section-product-name {
  border-bottom: solid 0.1rem #bdbdbd !important;
  border-radius: 0;
}
.Users .section-select.Mui-focused {
  border: solid 0.1rem #795548 !important;
  border-radius: 0.3rem;
}
.Users .section-top-border {
  border-top: 0.125rem solid #795548 !important;
}
.Users .checkbox-section-color {
  opacity: 0.5;
}
.Users .checkbox-section-color.Mui-checked {
  color: #795548;
  opacity: 1;
}
.Users .section-1px-border {
  border: 1px solid #795548 !important;
}
.Users .document-card:hover {
  border: 1px solid #795548 !important;
}
.Users .document-card:hover .btn-direct-upload {
  fill: #795548;
}
.Users .rdrInRange,
.Users .rdrStartEdge,
.Users .rdrEndEdge {
  color: #795548 !important;
}
.Users .rdrDayInPreview,
.Users .rdrDayStartPreview,
.Users .rdrDayEndPreview {
  border-bottom: #795548 solid 1px !important;
  border-top: #795548 solid 1px !important;
}
.Users .rdrDayStartPreview,
.Users .rdrDayStartOfWeek .rdrDayInPreview,
.Users .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left: #795548 solid 1px !important;
}
.Users .rdrDayEndPreview,
.Users .rdrDayEndOfWeek .rdrDayInPreview,
.Users .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right: #795548 solid 1px !important;
}
.Users .rdrDayToday .rdrDayNumber span:after {
  background: #795548;
}
.Users .section-edit-view-top-border {
  border-top: 0.75rem solid #795548;
}

.TimeKeeper .section-color {
  color: #FF9800 !important;
}
.TimeKeeper .section-switch-color .Mui-checked {
  color: #FF9800;
}
.TimeKeeper .section-switch-color .Mui-checked.Mui-disabled {
  opacity: 0.5;
}
.TimeKeeper .section-switch-color .Mui-checked.Mui-disabled + .MuiSwitch-track {
  opacity: 0.5;
}
.TimeKeeper .section-switch-color .Mui-checked + .MuiSwitch-track {
  background-color: #FF9800 !important;
}
.TimeKeeper .section-selected-side-border {
  border-left: 0.3rem solid #FF9800 !important;
}
.TimeKeeper .section-fill {
  fill: #FF9800;
}
.TimeKeeper .section-bg-color {
  background-color: #FF9800;
}
.TimeKeeper .section-bg-color:hover {
  background-color: #FF9800;
}
.TimeKeeper .section-hover-outline {
  color: #FF9800 !important;
}
.TimeKeeper .section-hover-outline:hover {
  background-color: white;
  border: 0.1rem solid #FF9800;
}
.TimeKeeper .disabled-section-button {
  opacity: 0.5;
}
.TimeKeeper .section-outline-color {
  border: 0.1rem solid #FF9800;
  color: #FF9800;
}
.TimeKeeper .section-bg-filter {
  background-color: rgba(255, 152, 0, 0.25);
}
.TimeKeeper .section-border {
  border: 1px solid #FF9800 !important;
}
.TimeKeeper button.section-bg-filter:hover {
  background-color: rgba(255, 152, 0, 0.25);
}
.TimeKeeper button.section-bg-color:hover,
.TimeKeeper label[role=button].section-bg-color:hover {
  background-color: #FF9800;
}
.TimeKeeper .MuiPaginationItem-page.Mui-selected {
  color: #FF9800;
  background-color: rgba(255, 152, 0, 0.1);
  pointer-events: none;
}
.TimeKeeper .section-input:focus-within {
  border: solid 0.1rem #FF9800 !important;
}
.TimeKeeper .section-product-name {
  border-bottom: solid 0.1rem #bdbdbd !important;
  border-radius: 0;
}
.TimeKeeper .section-select.Mui-focused {
  border: solid 0.1rem #FF9800 !important;
  border-radius: 0.3rem;
}
.TimeKeeper .section-top-border {
  border-top: 0.125rem solid #FF9800 !important;
}
.TimeKeeper .checkbox-section-color {
  opacity: 0.5;
}
.TimeKeeper .checkbox-section-color.Mui-checked {
  color: #FF9800;
  opacity: 1;
}
.TimeKeeper .section-1px-border {
  border: 1px solid #FF9800 !important;
}
.TimeKeeper .document-card:hover {
  border: 1px solid #FF9800 !important;
}
.TimeKeeper .document-card:hover .btn-direct-upload {
  fill: #FF9800;
}
.TimeKeeper .rdrInRange,
.TimeKeeper .rdrStartEdge,
.TimeKeeper .rdrEndEdge {
  color: #FF9800 !important;
}
.TimeKeeper .rdrDayInPreview,
.TimeKeeper .rdrDayStartPreview,
.TimeKeeper .rdrDayEndPreview {
  border-bottom: #FF9800 solid 1px !important;
  border-top: #FF9800 solid 1px !important;
}
.TimeKeeper .rdrDayStartPreview,
.TimeKeeper .rdrDayStartOfWeek .rdrDayInPreview,
.TimeKeeper .rdrDayStartOfWeek .rdrDayEndPreview {
  border-left: #FF9800 solid 1px !important;
}
.TimeKeeper .rdrDayEndPreview,
.TimeKeeper .rdrDayEndOfWeek .rdrDayInPreview,
.TimeKeeper .rdrDayEndOfWeek .rdrDayStartPreview {
  border-right: #FF9800 solid 1px !important;
}
.TimeKeeper .rdrDayToday .rdrDayNumber span:after {
  background: #FF9800;
}
.TimeKeeper .section-edit-view-top-border {
  border-top: 0.75rem solid #FF9800;
}

.section-input.placeholder .MuiSelect-select,
.section-select.placeholder .MuiSelect-select {
  color: #616161;
}

.styled-outline-input {
  padding: 0 1rem;
  height: 2.37rem;
  border: 0.125rem solid rgba(0, 0, 0, 0.4509803922);
  border-radius: 0.3rem !important;
}

.read-only-input {
  background-color: #F7F8F9;
}
.read-only-input:hover {
  background-color: #F7F8F9 !important;
}

.login-styled-outline-input {
  border: 0.031px solid #bdbdbd;
  border-radius: 0.003rem;
  padding: 0rem !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.login-styled-outline-input input {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

.styled-outline-input::before, .styled-outline-input::after,
.login-styled-outline-input input::before,
.login-styled-outline-input input::after,
.read-only-input::before,
.read-only-input::after,
.section-select::before,
.section-select::after,
.section-input::before,
.section-input::after,
.section-product-name::before,
.section-product-name::after {
  border-bottom: none !important;
}

.icon-green {
  fill: #00CA72;
  color: #00CA72 !important;
}
.icon-green .cls-2 {
  fill: #00CA72;
}

.icon-black {
  fill: rgba(0, 0, 0, 0.8705882353);
  color: rgba(0, 0, 0, 0.8705882353) !important;
}

.icon-gray {
  fill: #bdbdbd;
  color: #bdbdbd !important;
}

.icon-dark-gray {
  fill: #757575;
  color: #757575 !important;
}

.icon-timekeeper {
  fill: #FF9800;
  color: #FF9800 !important;
}

.icon-labManagement {
  fill: rgb(125, 155, 170);
  color: rgb(125, 155, 170) !important;
}

.icon-red {
  fill: #E95252;
  color: #E95252 !important;
}

.icon-white {
  fill: white;
}

.icon-blue {
  fill: #5F79E2;
  color: #5F79E2 !important;
}

.column-options-popover.MuiPopover-root .MuiPaper-root {
  border-radius: 0px 0px 4px 4px;
}
.column-options-popover .MuiCheckbox-root {
  padding: 0 0.3rem 0 0;
}
.column-options-popover .MuiCheckbox-root .MuiSvgIcon-root {
  font-size: 1.1rem;
}
.column-options-popover .MuiRadio-root {
  padding: 0 0.4rem 0 0;
}
.column-options-popover .MuiRadio-root .MuiSvgIcon-root {
  font-size: 1.1rem;
}
.column-options-popover [role=radiogroup] {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  justify-content: space-between;
  align-content: center;
  border-top: 1px solid #ebebeb;
}

.gray-700 {
  color: #616161 !important;
}

.gray-700-fill {
  fill: #616161;
}

.error {
  color: #E95252 !important;
}

.react-multi-carousel-list {
  position: unset !important;
}

.placeholderBlack::-moz-placeholder {
  color: rgba(0, 0, 0, 0.8705882353) !important;
}

.placeholderBlack::placeholder {
  color: rgba(0, 0, 0, 0.8705882353) !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrCalendarWrapper {
  font-size: 1rem !important;
}

.rdrDateRangePickerWrapper {
  margin: auto;
}

.rdrMonthName {
  display: none;
}/*# sourceMappingURL=site.css.map */