//colors
$black-87: #000000DE;
$danger-color: #E95252;
$acc-color: #A26FF0;
$protocols-color: #009688;
$reports-color: #00BCD4;
$income-color: #4CAF50;
$timekeeper-color: #FF9800;
$biomarkers-color: #F44336;
$operations-color: #3F51B5;
$order-inv-color: #2196F3;
$disabled-color: #B3B3B3;
$user-color: #795548;
$lab-man-color: rgb(125,155,170); /*#7D9BAA;*/
$order-inv-25: rgba(33,150,243, .25);
$lab-man-25: rgb(125,155,170, .25);
$accounting-25: rgb(162, 111, 240, .25);
$operations-25: rgb(63, 81, 181, .25);
$users-25: rgb(121,85,72, .25);
$protocols-25: rgb(0, 150, 136, .25);
$biomarkers-25: rgb(244, 67, 54, .25);
$timekeeper-25: rgb(255, 152, 0, .25);
$expenses-25: rgb(0, 188, 212, .25);
$income-25: rgb(76, 175, 80, .25);
$notifications-orderlate-color: #DB2127;
$notifications-ordered-color: #5F79E2;
$notifications-approved-color: #00CA72;
$notifications-received-color: #5F79E2;
$gray-700:#616161;
$gray-600:#757575;
$gray-550:#00000073;
$gray-400:#bdbdbd;
$gray-300:#e0e0e0;
$gray-200:#eeeeee;
$gray-100:#f5f5f5;
